import React, { useState } from "react";
import { Link } from "react-router-dom";
import MuiPhoneNumber from "mui-phone-number";
import { validateEmail, comparePasswords } from "../validators";
import { useNavigate } from "react-router-dom";
import { createAccount } from "../../../api/auth";
import { handleErrorMess } from "../../../components/Messages";

const Register = () => {
  const [email, setEmail] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [re_password, setRePassword] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      handleErrorMess("Podany adres e-mail jest nieprawidłowy.");
      return;
    }
    if (!password || !re_password) {
      handleErrorMess("Brak hasła");
      return;
    }
    if (!comparePasswords(password, re_password)) {
      handleErrorMess("Podane hasła się różnią.");
      return;
    }
    let result = await createAccount(email, password, phone_number);
    if (result.isOk) {
      navigate(`/weryfikacja?phone_number=${result.data.phone_number}`);
    } else {
      handleErrorMess(result.message);
    }
  };

  return (
    <div className="container-fluid" id="login">
      <div className="row h-100 align-items-center">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
          <div className="leftSide">
            <div className="text-center">
              <Link to="/" className="combackHome">
                <img
                  src="/assets/footerLogo.png"
                  className="footerLogo"
                  alt="logo"
                />
              </Link>
            </div>
            <h1>Rejestracja 👋</h1>
            <form onSubmit={handleSubmit}>
              <div className="group">
                <p>Adres e-mail</p>
                <input
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                  autoComplete={"email"}
                  placeholder="jankowalski@gmail.com"
                />
              </div>
              <div className="group">
                <p>Numer telefonu</p>
                <MuiPhoneNumber
                  defaultCountry={"pl"}
                  type="text"
                  required
                  onChange={(e) => setPhoneNumber(e)}
                  regions={["european-union"]}
                  autoComplete={"phone"}
                  placeholder="+48 123 123 123"
                />
              </div>
              <div className="group">
                <p>Hasło</p>
                <input
                  value={password}
                  required
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete={"new-password"}
                  type="password"
                />
              </div>
              <div className="group">
                <p>Powtórz hasło</p>
                <input
                  value={re_password}
                  required
                  onChange={(e) => setRePassword(e.target.value)}
                  autoComplete={"new-password"}
                  type="password"
                />
              </div>
              <label>
                <input type="checkbox" required /> Akceptuję{" "}
                <Link to="/regulamin">regulamin</Link> świadczenia usług przez
                TerapiPlus. Zapoznałem się z{" "}
                <Link to="/polityka-prywatnosci">
                  informacją o przetwarzaniu danych osobowych.
                </Link>
              </label>
              <button type="submit">Zarejestruj się</button>
            </form>

            <div className="noacc">
              <p>
                Masz już konto?
                <Link to="/zaloguj-sie"> Zaloguj się</Link>
              </p>
            </div>
            <div className="noacc mt-2">
              <p>
                Jesteś profesjonalistą?
                <Link to="/zarejestruj-sie/pro"> Załóż konto terapeuty!</Link>
              </p>
            </div>
          </div>
        </div>
        <div
          className="col-sm-12 col-md-12 col-lg-12 col-xl-6 d-flex d-xl-flex"
          id="rightSide"
        >
          <div className="object">
            <div className="circle">
              <img src="/assets/thunder.png" />
            </div>
            <img src="/assets/heroSearch.png" className="image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
