import { Routes, Route } from "react-router-dom";
import routes from "../routes/specialist-routes";
import { SideNavOuterToolbar as SideNavBarLayout } from "../layouts";
import { Suspense } from "react";

export default function Content() {
  return (
    <SideNavBarLayout title="TerapiPlus">
      <Suspense fallback={""}>
        <Routes>
          {routes.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
        </Routes>
      </Suspense>
    </SideNavBarLayout>
  );
}
