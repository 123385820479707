export const admin_navigation = [
  {
    text: "Home",
    path: "/admin",
    icon: "home",
  },
  {
    text: "Blog",
    path: "/admin/blog",
    icon: "image",
  },
  {
    text: "Kategorie",
    path: "/admin/kategorie",
    icon: "dragvertical",
  },
  {
    text: "Kliniki",
    path: "/admin/kliniki",
    icon: "group",
  },
  {
    text: "Specjalisci",
    path: "/admin/specjalisci",
    icon: "user",
  },
  {
    text: "Pacjenci",
    path: "/admin/pacjenci",
    icon: "group",
  },
  {
    text: "Pytania",
    path: "/admin/pytania",
    icon: "blockquote",
  },
  {
    text: "Odpowiedzi",
    path: "/admin/odpowiedzi",
    icon: "group",
  },
  {
    text: "Specjalizacje",
    path: "/admin/specjalizacje",
    icon: "increaselinespacing",
  },
  {
    text: "Użytkownicy",
    path: "/admin/uzytkownicy",
    icon: "group",
  },
  {
    text: "Wizyty",
    path: "/admin/wizyty",
    icon: "checklist",
  },
];
