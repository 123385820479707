import {
  HomePage,
  SettingsPage,
  PatientPage,
  PatientsPage,
  AddPatientPage,
  ProfilePage,
  AddPatientMany,
  AnalizaPage,
  Articles,
  AddArticle,
  Questions,
  Answers,
  UpdateArticle,
  Harmonogram,
  Platnosci,
  Uslugi,
  Lokalizacje,
  Visits,
  AddVisit,
  Visit,
} from "../pages/Specialist";
import { withNavigationWatcher } from "../contexts/navigation";

const routes = [
  {
    path: "/terapeuta",
    element: HomePage,
  },
  {
    path: "/terapeuta/wizyty",
    element: Visits,
  },
  {
    path: "/terapeuta/wizyty/dodaj",
    element: AddVisit,
  },
  {
    path: "/terapeuta/wizyty/:visit_id",
    element: Visit,
  },
  {
    path: "/terapeuta/pacjenci",
    element: PatientsPage,
  },
  {
    path: "/terapeuta/pacjenci/dodaj",
    element: AddPatientPage,
  },
  {
    path: "/terapeuta/pacjenci/dodaj-masowo",
    element: AddPatientMany,
  },
  {
    path: "/terapeuta/pacjenci/:patient_id",
    element: PatientPage,
  },
  {
    path: "/terapeuta/profil",
    element: ProfilePage,
  },
  {
    path: "/terapeuta/ustawienia",
    element: SettingsPage,
  },
  {
    path: "/terapeuta/analiza",
    element: AnalizaPage,
  },
  {
    path: "/terapeuta/artykuly",
    element: Articles,
  },
  {
    path: "/terapeuta/dodaj-artykul",
    element: AddArticle,
  },
  {
    path: "/terapeuta/artykuly/:article_id",
    element: UpdateArticle,
  },
  {
    path: "/terapeuta/odpowiedzi",
    element: Answers,
  },
  {
    path: "/terapeuta/pytania",
    element: Questions,
  },
  {
    path: "/terapeuta/ustawienia/harmonogram",
    element: Harmonogram,
  },
  {
    path: "/terapeuta/ustawienia/uslugi",
    element: Uslugi,
  },
  {
    path: "/terapeuta/ustawienia/lokalizacje",
    element: Lokalizacje,
  },
  {
    path: "/terapeuta/ustawienia/platnosci",
    element: Platnosci,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  };
});
