import React from "react";
export const HomePage = React.lazy(() => import("./Home/home"));
export const ProfilePage = React.lazy(() => import("./Profile/profile"));
export const SettingsPage = React.lazy(() => import("./Ustawienia/ustawienia"));
export const AddPatientPage = React.lazy(() =>
  import("./AddPatient/addpatient")
);
export const PatientPage = React.lazy(() => import("./Patient/patient"));
export const PatientsPage = React.lazy(() => import("./Patients/patients"));
export const AddPatientMany = React.lazy(() =>
  import("./AddPatientMany/addpatientmany")
);
export const AnalizaPage = React.lazy(() => import("./Analiza/Analiza"));
export const Articles = React.lazy(() => import("./Articles/Index"));
export const AddArticle = React.lazy(() => import("./AddArticle/Index"));
export const Questions = React.lazy(() => import("./Questions/Index"));
export const Answers = React.lazy(() => import("./Answers/Index"));
export const UpdateArticle = React.lazy(() =>
  import("./UpdateArticle/UpdateArticle")
);
export const Harmonogram = React.lazy(() =>
  import("./Harmonogram/Harmonogram")
);
export const Lokalizacje = React.lazy(() => import("./Lokalizacje/Index"));
export const Platnosci = React.lazy(() => import("./Platnosci/Index"));
export const Uslugi = React.lazy(() => import("./Uslugi/Index"));
export const Visits = React.lazy(() => import("./Visits/Visits"));
export const AddVisit = React.lazy(() => import("./AddVisit/AddVisit"));
export const Visit = React.lazy(() => import("./Visit/Index"));
