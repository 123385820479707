import {
  HomePage,
  PatientPage,
  PatientsPage,
  AddPatientPage,
  AddPatientMany,
  AnalizaPage,
  Articles,
  AddArticle,
  Questions,
  Answers,
  UpdateArticle,
  Harmonogram,
  Platnosci,
  Uslugi,
  Lokalizacje,
  Visits,
  AddVisit,
  Visit,
  SpecialistPage,
  SpecialistsPage,
  SpecialistAddPage,
  HarmonogramSelect,
  ServicesSelect,
  LokalizacjeSelect,
} from "../pages/Klinika";
import { withNavigationWatcher } from "../contexts/navigation";

const routes = [
  {
    path: "/klinika",
    element: HomePage,
  },
  {
    path: "/klinika/wizyty",
    element: Visits,
  },
  {
    path: "/klinika/wizyty/dodaj",
    element: AddVisit,
  },
  {
    path: "/klinika/wizyty/:visit_id",
    element: Visit,
  },
  {
    path: "/klinika/pacjenci",
    element: PatientsPage,
  },
  {
    path: "/klinika/pacjenci/dodaj",
    element: AddPatientPage,
  },
  {
    path: "/klinika/pacjenci/dodaj-masowo",
    element: AddPatientMany,
  },
  {
    path: "/klinika/pacjenci/:patient_id",
    element: PatientPage,
  },
  {
    path: "/klinika/artykuly",
    element: Articles,
  },
  {
    path: "/klinika/dodaj-artykul",
    element: AddArticle,
  },
  {
    path: "/klinika/artykuly/:article_id",
    element: UpdateArticle,
  },
  {
    path: "/klinika/odpowiedzi",
    element: Answers,
  },
  {
    path: "/klinika/pytania",
    element: Questions,
  },
  {
    path: "/klinika/ustawienia/harmonogram",
    element: HarmonogramSelect,
  },
  {
    path: "/klinika/ustawienia/harmonogram/:specialist_id",
    element: Harmonogram,
  },
  {
    path: "/klinika/ustawienia/uslugi",
    element: ServicesSelect,
  },
  {
    path: "/klinika/ustawienia/uslugi/:specialist_id",
    element: Uslugi,
  },
  {
    path: "/klinika/ustawienia/lokalizacje",
    element: LokalizacjeSelect,
  },
  {
    path: "/klinika/ustawienia/lokalizacje/:specialist_id",
    element: Lokalizacje,
  },
  {
    path: "/klinika/ustawienia/platnosci",
    element: Platnosci,
  },
  {
    path: "/klinika/analiza",
    element: AnalizaPage,
  },
  {
    path: "/klinika/specjalisci",
    element: SpecialistsPage,
  },
  {
    path: "/klinika/specjalisci/dodaj",
    element: SpecialistAddPage,
  },
  {
    path: "/klinika/specjalisci/:specialist_id",
    element: SpecialistPage,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  };
});
