import React, { useState } from "react";
import { Link } from "react-router-dom";
import { validateEmail } from "../validators";
import { handleErrorMess, handleSuccMess } from "../../../components/Messages";
import { resetPassword } from "../../../api/auth";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const handleSendForm = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      handleErrorMess("Nie prawidłowy adres e-mail");
      return;
    }
    let result = await resetPassword(email);
    if (result.isOk) {
      handleSuccMess("Twoje nowe hasło zostało wysłane na podany adres e-mail");
      setEmail("");
    } else {
      handleErrorMess(result.message);
    }
  };
  return (
    <div className="container-fluid" id="login">
      <div className="row h-100 align-items-center">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
          <div className="leftSide">
            <div className="text-center">
              <Link to="/" className="combackHome">
                <img
                  src="/assets/footerLogo.png"
                  className="footerLogo"
                  alt="logo"
                />
              </Link>
            </div>
            <h1>Przypomnij hasło 👋</h1>
            <form onSubmit={handleSendForm}>
              <div className="group">
                <p>Adres e-mail</p>
                <input
                  autoComplete={"email"}
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="jankowalski@gmail.com"
                />
              </div>

              <button type="submit">Przypomij hasło</button>
            </form>
            <div className="forgetWrap mt-4 text-center">
              <Link to="/zaloguj-sie">Powrót</Link>
            </div>
            <div className="forgetWrap mt-4 text-center">
              <Link to="/">Strona główna</Link>
            </div>
          </div>
        </div>
        <div
          className="col-sm-12 col-md-12 col-lg-12 col-xl-6 d-flex d-xl-flex"
          id="rightSide"
        >
          <div className="object">
            <div className="circle">
              <img src="/assets/thunder.png" />
            </div>
            <img src="/assets/heroSearch.png" className="image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
