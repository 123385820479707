export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const comparePasswords = (password, rePassword) => {
  return password === rePassword;
};

export const validateZipCode = (code) => {
  return (
    String(code)
      .toLowerCase()
      .match(/^\d{2}-\d{3}$/) !== null
  );
};
