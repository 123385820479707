import React from "react";
export const Home = React.lazy(() => import("./home/home"));
export const Specialists = React.lazy(() =>
  import("./specialists/specialists")
);
export const NotFound = React.lazy(() => import("./home/home"));
export const Privacy = React.lazy(() => import("./privacy/privacy"));
export const Reguations = React.lazy(() => import("./regulations/regulations"));
export const Specialist = React.lazy(() => import("./specialist/specialist"));
export const AskSpecialist = React.lazy(() => import("./AskSpecialist/Index"));
export const Blog = React.lazy(() => import("./Blog/Index"));
export const Wpis = React.lazy(() => import("./Wpis/Index"));
export const AboutUs = React.lazy(() => import("./AboutUs/Index"));
export const Porady = React.lazy(() => import("./Porady/Index"));
export const Porada = React.lazy(() => import("./Porada/Index"));
export const Professional = React.lazy(() =>
  import("./professional/Professional")
);
export const AddVisit = React.lazy(() => import("./AddVisit/Index"));
export const ThankYouVisit = React.lazy(() => import("./ThankYouVisit/Index"));
