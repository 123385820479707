import "./polyfills";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import CookieConsent from "react-cookie-consent";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <App />
    <NotificationContainer />
    <CookieConsent
      location="bottom"
      buttonText="Zgadzam się."
      cookieName="terapiPlusCookie"
      style={{ background: "#007b83" }}
      buttonStyle={{
        background: "white",
        color: "black",
        fontSize: "15px",
        borderRadius: "5px",
      }}
      expires={365}
    >
      Korzystamy z plików cookies w celu dostarczenia Ci możliwie jak
      najlepszych doświadczeń związanych z korzystaniem z naszego portalu
    </CookieConsent>
  </>
);
