import React, { useContext, useState } from "react";
import "./login.scss";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { validateEmail } from "../validators";
import { getUser, signIn } from "../../../api/auth";
import { UserContext } from "../../../contexts/auth";
import { NotificationError } from "../../../components/Notifications";

const Login = () => {
  const authCtx = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      NotificationError("Nie prawidłowy adres email");
      return;
    }
    if (!password) {
      NotificationError("Brak hasła");
      return;
    }
    const response = await signIn(email, password);
    if (response.isOk) {
      const expirationTime = new Date(
        new Date().getTime() + 1000 * 60 * 60 * 24 * 100
      );
      authCtx.login(response.data.access_token, expirationTime);
      await authCtx.userSet();
      if (searchParams.get("next")) {
        navigate(searchParams.get("next"));
        return;
      } else {
        let user_result = await getUser(response.data.access_token);
        if (!user_result.isOk) {
          NotificationError("Coś poszło nie tak...");
          return;
        }
        if (user_result.data.is_admin) {
          navigate("/admin");
          return;
        }
        if (user_result.data.is_clinic) {
          navigate("/klinika");
          return;
        }
        if (user_result.data.is_specialist) {
          navigate("/terapeuta");
          return;
        }

        navigate("/moje-konto");
      }
      return;
    } else {
      NotificationError(response.message);
    }
  };

  return (
    <div className="container-fluid" id="login">
      <div className="row h-100 align-items-center">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
          <div className="leftSide">
            <div className="text-center">
              <Link to="/" className="combackHome">
                <img
                  src="/assets/footerLogo.png"
                  className="footerLogo"
                  alt="logo"
                />
              </Link>
            </div>
            <h1>Logowanie 👋</h1>
            <form onSubmit={handleSubmit}>
              <div className="group">
                <p>Adres e-mail</p>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete={"email"}
                  type="text"
                  placeholder="jankowalski@gmail.com"
                />
              </div>
              <div className="group">
                <p>Hasło</p>
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete={"current-password"}
                  type="password"
                />
              </div>
              <div className="forgetWrap">
                <Link to="/zapomnialem-hasla"> Zapomniałeś hasła?</Link>
              </div>

              <button type="submit">Zaloguj się</button>
            </form>

            <div className="noacc">
              <p>
                Nie masz konta?{" "}
                <Link to="/zarejestruj-sie">Zarejestruj się</Link>
              </p>
            </div>
            <div className="forgetWrap mt-4 text-center">
              <Link to="/">Strona główna</Link>
            </div>
          </div>
        </div>
        <div
          className="col-sm-12 col-md-12 col-lg-12 col-xl-6 d-flex d-xl-flex"
          id="rightSide"
        >
          <div className="object">
            <div className="circle">
              <img src="/assets/thunder.png" />
            </div>
            <img src="/assets/heroSearch.png" className="image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
