import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MuiPhoneNumber from "mui-phone-number";
import { validateEmail, comparePasswords } from "../validators";
import { useNavigate } from "react-router-dom";
import { createAccount } from "../../../api/auth";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { handleErrorMess } from "../../../components/Messages";
import { UserContext } from "../../../contexts/auth";
import { da } from "@faker-js/faker";

const Register = () => {
  const { user, isLoggedIn, token, userSet } = useContext(UserContext);
  const [type, setType] = useState("Specjalista");

  const [email, setEmail] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [re_password, setRePassword] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // VALIDATIONS

    if (!validateEmail(email)) {
      return handleErrorMess("Podany adres e-mail jest nieprawidłowy.");
    }
    if (type === "Placówka") {
      if (!name) {
        return handleErrorMess("Brak nazwy placówki");
      }
    }
    if (!isLoggedIn) {
      if (!password || !re_password) {
        return handleErrorMess("Brak hasła");
      }
    }

    if (!comparePasswords(password, re_password)) {
      return handleErrorMess("Podane hasła się różnią.");
    }
    // VALIDATIONS
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        email,
        phone_number,
        first_name,
        name,
        last_name,
        password,
      }),
    };
    let urlExt = type === "Specjalista" ? "/specialist/" : "/clinic/";
    const response = await fetch(
      "https://backend.terapiplus.pl/api/v1" + urlExt,
      requestOptions
    );
    let data = await response.json();
    userSet();
    if (response.ok) {
      if (!user) {
        navigate(`/weryfikacja?phone_number=${phone_number}`);
        return;
      } else {
        if (type === "Placówka") {
          navigate("/klinika");
          return;
        } else {
          navigate("/terapeuta");
          return;
        }
      }
    }
    if (response.status === 400) {
      handleErrorMess(
        "Użytkownik z podanym adresem e-mail bądź numerem telefonu już istnieje. Jeżeli jest to Twoje konto, zaloguj się i uzupełnij ponownie formularz."
      );
      return;
    }
    if (response.status === 409 && user) {
      navigate("/klinika");
      return;
    }
    if (response.status !== 400) {
      if (isLoggedIn) {
        if (user && user.is_clinic) {
          navigate("/klinika");
          return;
        }
        if (user && user.is_specialist) {
          navigate("/terapeuta");
          return;
        }
      }
      {
        handleErrorMess(data.detail);
        if (data.detail === "Profil specjalisty został już utworzony.") {
          navigate("/terapeuta");
        }
      }
    }
  };
  useEffect(() => {
    if (isLoggedIn) {
      setEmail(user.email);
      setPhoneNumber(user.phone_number);
      setFirstName(user.first_name);
      setLastName(user.last_name);
    }
  }, [user]);

  return (
    <div className="container-fluid registerPro" id="login">
      <div className="row h-100 align-items-center">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-8">
          <div className="leftSide">
            <div className="text-center">
              <Link to="/" className="combackHome">
                <img
                  src="/assets/footerLogo.png"
                  className="footerLogo"
                  alt="logo"
                />
              </Link>
            </div>
            <form onSubmit={handleSubmit}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Typ profilu
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="Specialista"
                  name="registerAS"
                >
                  <FormControlLabel
                    value="Specjalista"
                    control={
                      <Radio
                        onChange={() => setType("Specjalista")}
                        checked={type === "Specjalista"}
                      />
                    }
                    label="Specjalista"
                  />
                  <FormControlLabel
                    value="Placówka"
                    control={
                      <Radio
                        onChange={() => setType("Placówka")}
                        checked={type === "Placówka"}
                      />
                    }
                    label="Placówka"
                  />
                </RadioGroup>
              </FormControl>
              <div className="row gy-3">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                  <div className="group">
                    <p>Adres e-mail *</p>
                    <input
                      value={email}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      type="text"
                      autoComplete={"email"}
                      placeholder="jankowalski@gmail.com"
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                  <div className="group">
                    <p>Numer telefonu *</p>
                    <MuiPhoneNumber
                      defaultCountry={"pl"}
                      type="text"
                      required
                      value={phone_number}
                      onChange={(e) => setPhoneNumber(e)}
                      regions={["european-union"]}
                      autoComplete={"phone"}
                      placeholder="+48 123 123 123"
                    />
                  </div>
                </div>
              </div>
              {type === "Placówka" && (
                <div className="group">
                  <p>Nazwa placówki * </p>
                  <input
                    value={name}
                    required
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                  />
                </div>
              )}
              <div className="row gy-3">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                  <div className="group">
                    <p>Imię * </p>
                    <input
                      value={first_name}
                      required
                      onChange={(e) => setFirstName(e.target.value)}
                      type="text"
                      autoComplete={"first_name"}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                  <div className="group">
                    <p>Nazwisko * </p>
                    <input
                      value={last_name}
                      required
                      onChange={(e) => setLastName(e.target.value)}
                      type="text"
                      autoComplete={"last_name"}
                    />
                  </div>
                </div>
              </div>
              {!isLoggedIn && (
                <div className="row gy-3">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                    <div className="group">
                      <p>Hasło *</p>
                      <input
                        value={password}
                        required
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete={"new-password"}
                        type="password"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                    <div className="group">
                      <p>Powtórz hasło *</p>
                      <input
                        value={re_password}
                        required
                        onChange={(e) => setRePassword(e.target.value)}
                        autoComplete={"new-password"}
                        type="password"
                      />
                    </div>
                  </div>
                </div>
              )}
              <label>
                <input type="checkbox" required /> Akceptuję{" "}
                <Link to="/regulamin">regulamin</Link> świadczenia usług przez
                TerapiPlus. Zapoznałem się z{" "}
                <Link to="/polityka-prywatnosci">
                  informacją o przetwarzaniu danych osobowych.
                </Link>
              </label>
              <button type="submit">Zarejestruj się</button>
            </form>

            <div className="noacc">
              <p>
                Masz już konto?
                <Link to="/zaloguj-sie"> Zaloguj się</Link>
              </p>
            </div>
          </div>
        </div>
        <div
          className="col-sm-12 col-md-12 col-lg-12 col-xl-4 d-none d-xl-flex"
          id="rightSide"
        >
          <div className="object">
            <div className="objectText">
              <h6>Daj się znaleźć użytkownikom! </h6>
              <h6>
                Korzystaj z naszego systemu CMS i sprawnie zarządzaj swoją
                pracą!
              </h6>
              <h6>Zadbaj o swoją pozycje w internecie</h6>
              <h6>Dostawaj i wysyłaj powiadomienia SMS</h6>
              <h6 className="py-5">
                Utwórz Swoje konto teraz i zyskaj <strong>3 Miesiące </strong>
                gratis!
              </h6>
            </div>

            <div className="circle">
              <img src="/assets/thunder.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
