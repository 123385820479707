import {
  Blog,
  BlogDetal,
  Kategorie,
  KategorieDetal,
  KategorieDodaj,
  Kliniki,
  Klinika,
  Pacjenci,
  Pacjent,
  Pytania,
  Specjalisci,
  SpecjalistaDetal,
  Specjalizacje,
  SpecjalizacjeDodaj,
  Uzytkownicy,
  UzytkownikDetal,
  Wizyty,
  WizytaDetal,
  Home,
  Odpowiedzi,
  Odpowiedz,
  Specjalizacja,
} from "../pages/Admin";
import { withNavigationWatcher } from "../contexts/navigation";

const routes = [
  {
    path: "/admin",
    element: Home,
  },
  {
    path: "/admin/blog",
    element: Blog,
  },
  {
    path: "/admin/blog/:blog_id",
    element: BlogDetal,
  },
  {
    path: "/admin/kategorie",
    element: Kategorie,
  },
  {
    path: "/admin/kategorie/dodaj",
    element: KategorieDodaj,
  },
  {
    path: "/admin/kategorie/:category_id",
    element: KategorieDetal,
  },
  {
    path: "/admin/kliniki",
    element: Kliniki,
  },
  {
    path: "/admin/kliniki/:klinika_id",
    element: Klinika,
  },
  {
    path: "/admin/pacjenci",
    element: Pacjenci,
  },
  {
    path: "/admin/pacjenci/:patient_id",
    element: Pacjent,
  },
  {
    path: "/admin/pytania",
    element: Pytania,
  },
  {
    path: "/admin/odpowiedzi",
    element: Odpowiedzi,
  },
  {
    path: "/admin/odpowiedzi/:answer_id",
    element: Odpowiedz,
  },
  {
    path: "/admin/specjalisci",
    element: Specjalisci,
  },
  {
    path: "/admin/specjalisci/:specialist_id",
    element: SpecjalistaDetal,
  },
  {
    path: "/admin/specjalizacje",
    element: Specjalizacje,
  },
  {
    path: "/admin/specjalizacje/:spec_id",
    element: Specjalizacja,
  },
  {
    path: "/admin/specjalizacje/dodaj",
    element: SpecjalizacjeDodaj,
  },
  {
    path: "/admin/uzytkownicy",
    element: Uzytkownicy,
  },
  {
    path: "/admin/uzytkownicy/:user_id",
    element: UzytkownikDetal,
  },
  {
    path: "/admin/wizyty",
    element: Wizyty,
  },
  {
    path: "/admin/wizyty/:visit_id",
    element: WizytaDetal,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  };
});
