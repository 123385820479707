import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  Questions,
  AddQuestion,
  Appointments,
  Settings,
  Specialists,
  Appointment,
} from "../pages/User";
import { Suspense, useContext, useEffect } from "react";
import { UserContext } from "../contexts/auth";

export default function UserContent() {
  const { isLoggedIn } = useContext(UserContext);
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(`/zaloguj-sie?next=${currentPath}`);
    }
  }, []);

  return (
    <Suspense fallback={""}>
      <Routes>
        <Route path="/moje-konto" element={<Appointments />} />
        <Route path="/moje-konto/wizyta/:visit_id" element={<Appointment />} />
        <Route path="/moje-konto/specjalisci" element={<Specialists />} />
        <Route path="/moje-konto/pytania" element={<Questions />} />
        <Route path="/moje-konto/dodaj-pytanie" element={<AddQuestion />} />
        <Route path="/moje-konto/ustawienia" element={<Settings />} />
      </Routes>
    </Suspense>
  );
}
