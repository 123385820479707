import { Routes, Route, Navigate } from "react-router-dom";
import {
  Home,
  Specialists,
  NotFound,
  Reguations,
  Privacy,
  Specialist,
  AskSpecialist,
  Blog,
  Wpis,
  AboutUs,
  Porady,
  Porada,
  Professional,
  AddVisit,
  ThankYouVisit,
} from "../pages/Core";
import {
  Login,
  Register,
  ResetPassword,
  PhoneVerification,
  RegisterPro,
} from "../pages/Accounts";
import { Suspense } from "react";

export default function UnauthenticatedContent() {
  return (
    <Suspense fallback={""}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/szukaj" element={<Specialists />} />
        <Route path="/regulamin" element={<Reguations />} />
        <Route path="/umow-wizyte/" element={<AddVisit />} />
        <Route path="/o-nas" element={<AboutUs />} />
        <Route path="/porady" element={<Porady />} />
        <Route path="/porada/:porada_id" element={<Porada />} />
        <Route path="/polityka-prywatnosci" element={<Privacy />} />
        <Route path="/zaloguj-sie" element={<Login />} />
        <Route path="/zarejestruj-sie/pro" element={<RegisterPro />} />
        <Route path="/zarejestruj-sie" element={<Register />} />
        <Route path="/zapomnialem-hasla" element={<ResetPassword />} />
        <Route path="/weryfikacja" element={<PhoneVerification />} />
        <Route path="/zapytaj-terapeute" element={<AskSpecialist />} />
        <Route path="/profesjonalisci" element={<Professional />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:category_slug" element={<Blog />} />
        <Route path="/blog/specjalista/:specialist_id" element={<Blog />} />
        <Route path="/blog/:post_id/:post_slug" element={<Wpis />} />
        <Route path="/umow-wizyte/notify" element={<ThankYouVisit />} />
        <Route
          path="/umow-wizyte/dziekuje/:visit_id"
          element={<ThankYouVisit />}
        />
        <Route
          path="/:specialist_id/:specialist_slug"
          element={<Specialist />}
        />
        <Route path="/:main_category" element={<Specialists />} />

        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </Suspense>
  );
}
