export async function signIn(email, password) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
      },
      body: JSON.stringify(
        `grant_type=&username=${email}&password=${password}&scope=&client_id=&client_secret=`
      ),
    };
    const response = await fetch(
      "https://backend.terapiplus.pl/api/v1/auth/login/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function getUser(token) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.terapiplus.pl/api/v1/auth/me/",
      requestOptions
    );
    const data = await response.json();

    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
    };
  } catch {
    return {
      isOk: false,
    };
  }
}

export async function createAccount(email, password, phone_number) {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
        phone_number,
      }),
    };
    const response = await fetch(
      "https://backend.terapiplus.pl/api/v1/auth/signup/",
      requestOptions
    );

    let data = await response.json();

    if (response.ok) {
      return {
        data: data,
        isOk: true,
      };
    } else {
      return {
        message: data.detail,
        isOk: false,
      };
    }
  } catch (e) {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function verifyAccount(phone_number, code) {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        activation_code: code,
        phone_number,
      }),
    };
    const response = await fetch(
      "https://backend.terapiplus.pl/api/v1/auth/verify/",
      requestOptions
    );

    let data = await response.json();

    if (response.ok) {
      return {
        data: data,
        isOk: true,
      };
    } else {
      return {
        message: data.detail,
        isOk: false,
      };
    }
  } catch (e) {
    console.log(e);
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function sendVerificationCode(phone_number, code) {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phone_number: phone_number.trim(),
      }),
    };
    const response = await fetch(
      "https://backend.terapiplus.pl/api/v1/auth/verify-send/",
      requestOptions
    );

    let data = await response.json();

    if (response.ok) {
      return {
        isOk: true,
      };
    } else {
      return {
        message: data.detail,
        isOk: false,
      };
    }
  } catch (e) {
    console.log(e);
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function changePassword(token, data_in) {
  try {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data_in),
    };
    const response = await fetch(
      "https://backend.terapiplus.pl/api/v1/user/change-password/",
      requestOptions
    );

    let data = await response.json();

    if (response.ok) {
      return {
        isOk: true,
      };
    } else {
      return {
        message: data.detail,
        isOk: false,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się zmienić hasła",
    };
  }
}

export async function resetPassword(email) {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    };
    const response = await fetch(
      "https://backend.terapiplus.pl/api/v1/auth/forgot-password/",
      requestOptions
    );

    let data = await response.json();

    if (response.ok) {
      return {
        isOk: true,
      };
    } else {
      return {
        message: data.detail,
        isOk: false,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się zmienić hasła",
    };
  }
}

export async function createAccountV2(_data) {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        first_name: _data.first_name || "",
        last_name: _data.last_name || "",
        email: _data.email,
        password: _data.password,
        phone_number: _data.phone_number,
      }),
    };
    const response = await fetch(
      "https://backend.terapiplus.pl/api/v1/auth/signup/",
      requestOptions
    );

    let data = await response.json();

    if (response.ok) {
      return {
        data: data,
        isOk: true,
      };
    } else {
      return {
        message: data.detail,
        isOk: false,
      };
    }
  } catch (e) {
    console.log(e);
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}
