export const navigation = [
  {
    text: "Kalendarz",
    path: "/terapeuta",
    icon: "event",
  },
  {
    text: "Wizyty",
    icon: "card",
    items: [
      {
        text: "Wszystkie wizyty",
        path: "/terapeuta/wizyty",
      },
      {
        text: "Dodaj wizytę",
        path: "/terapeuta/wizyty/dodaj",
      },
    ],
  },
  {
    text: "Pacjenci",
    icon: "group",
    items: [
      {
        text: "Wszyscy pacjenci",
        path: "/terapeuta/pacjenci",
      },
      {
        text: "Dodaj pacjenta",
        path: "/terapeuta/pacjenci/dodaj",
      },
      {
        text: "Dodaj masowo",
        path: "/terapeuta/pacjenci/dodaj-masowo",
      },
    ],
  },
  {
    text: "Moje konto",
    icon: "rename",
    path: "/terapeuta/profil",
  },
  {
    text: "Ustawienia",
    icon: "preferences",
    items: [
      {
        text: "Usługi",
        path: "/terapeuta/ustawienia/uslugi",
      },
      {
        text: "Lokalizacje",
        path: "/terapeuta/ustawienia/lokalizacje",
      },
      {
        text: "Harmonogram",
        path: "/terapeuta/ustawienia/harmonogram",
      },
    ],
  },
  { text: "Statystyki", icon: "chart", path: "/terapeuta/analiza" },
  {
    text: "Artykuły",
    icon: "edit",
    items: [
      { text: "Twoje artykuły", path: "/terapeuta/artykuly" },
      { text: "Dodaj artykuł", path: "/terapeuta/dodaj-artykul" },
    ],
  },
  {
    text: "Pytania",
    icon: "comment",
    items: [
      { text: "Twoje odpowiedzi", path: "/terapeuta/odpowiedzi" },
      { text: "Przeglądaj pytania", path: "/terapeuta/pytania" },
    ],
  },
  {
    icon: "money",
    text: "Płatności",
    path: "/terapeuta/ustawienia/platnosci",
  },
];
