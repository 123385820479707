import React, { useContext, useEffect, useState } from "react";
import ReactInputVerificationCode from "react-input-verification-code";
import notify from "devextreme/ui/notify";
import {
  verifyAccount,
  sendVerificationCode,
  getUser,
} from "../../../api/auth";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../contexts/auth";

const PhoneVerification = () => {
  const authCtx = useContext(UserContext);
  const [waiting, setWaiting] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [time, setTime] = useState(undefined);

  const navigate = useNavigate();

  const handleCompleted = (e) => {
    if (!e.includes("·")) {
      sendRequest(e);
    }
  };

  useEffect(() => {
    const countdown = setInterval(() => {
      if (time > 0) {
        setTime(time - 1);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [time]);

  const waitForTenSeconds = () => {
    setWaiting(true);
    setTime(10);
    setTimeout(() => {
      setWaiting(false);
    }, 10000); // 10000 milliseconds = 10 seconds
  };
  let phone_number = searchParams.get("phone_number");

  const sendRequest = async (code) => {
    if (!phone_number) {
      navigate("/zaloguj-sie");
      return;
    }
    const response = await verifyAccount(phone_number, code);
    if (response.isOk) {
      const expirationTime = new Date(
        new Date().getTime() + 1000 * 60 * 60 * 24 * 100
      );
      authCtx.login(response.data.access_token, expirationTime);
      let user_data = await getUser(response.data.access_token);
      if (user_data.isOk) {
        if (user_data.data.is_clinic) {
          navigate("/klinika");
          return;
        }
        if (user_data.data.is_specialist) {
          navigate("/terapeuta");
          return;
        } else {
          navigate("/moje-konto");
          return;
        }
      }
      navigate("/moje-konto");

      return;
    } else {
      notify(
        {
          message: response.message,
          position: {
            my: "center top",
            at: "center top",
          },
          width: 180,
        },

        "error",
        2000
      );
    }
    return;
  };

  const sendAgain = async (code) => {
    const response = await sendVerificationCode(phone_number, code);
    waitForTenSeconds();
    notify(
      {
        message: "Wysłano wiadomość SMS z kodem",
        position: {
          my: "center top",
          at: "center top",
        },
        width: 180,
      },

      "success",
      2000
    );
    return;
  };

  return (
    <div className="container-fluid" id="login">
      <div className="row h-100 align-items-center">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
          <div className="leftSide gap-3 align-items-center">
            <h1>Zweryfikuj numer telefonu</h1>
            <ReactInputVerificationCode
              autoFocus={true}
              onCompleted={handleCompleted}
            />
            <p className="info">
              Wysłaliśmy wiadomość na podany przy rejestracji numer telefonu.
              Wpisz kod z wiadomości SMS aby aktywować swoje konto.
            </p>
            {waiting ? (
              <p className="problems">
                Musisz odczekać <span>{time} sekund</span> aby wysłać ponownie
                wiadomość
              </p>
            ) : (
              <p className="problems">
                Sms nie doszedł?{" "}
                <span onClick={sendAgain}>Wyślij wiadomość ponownie.</span>
              </p>
            )}
          </div>
        </div>
        <div
          className="col-sm-12 col-md-12 col-lg-12 col-xl-6 d-flex d-xl-flex"
          id="rightSide"
        >
          <div className="object">
            <div className="circle">
              <img src="/assets/thunder.png" />
            </div>
            <img src="/assets/heroSearch.png" className="image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneVerification;
