import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
import React, { useContext } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./dx-styles.scss";
import LoadPanel from "devextreme-react/load-panel";
import { NavigationProvider } from "./contexts/navigation";
import { UserContext, UserProvider } from "./contexts/auth";
import { useScreenSizeClass } from "./utils/media-query";
import Content from "./contents/Content";
import ContentAdmin from "./contents/ContentAdmin";
import ContentKlinika from "./contents/ContantKlinika";
import UserContent from "./contents/UserContent";
import UnauthenticatedContent from "./contents/UnauthenticatedContent";
import { useLocation } from "react-router-dom";

function App() {
  const { user, isLoggedIn } = useContext(UserContext);
  const location = useLocation();
  const currentPath = location.pathname;

  // Walidować czy user jest terapeutą / kliniką / adminem
  if (currentPath.startsWith("/moje-konto") && isLoggedIn) {
    return <UserContent />;
  }
  if (
    currentPath.startsWith("/terapeuta") &&
    isLoggedIn &&
    user &&
    user.is_specialist
  ) {
    return <Content />;
  }
  if (
    currentPath.startsWith("/klinika") &&
    isLoggedIn &&
    user &&
    user.is_clinic
  ) {
    return <ContentKlinika />;
  }
  if (currentPath.startsWith("/admin") && isLoggedIn && user && user.is_admin) {
    return <ContentAdmin />;
  }

  return <UnauthenticatedContent />;
}

export default function Root() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router>
      <UserProvider>
        <NavigationProvider>
          <div className={`app ${screenSizeClass}`}>
            <App />
          </div>
        </NavigationProvider>
      </UserProvider>
    </Router>
  );
}
