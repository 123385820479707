import React from "react";
export const Blog = React.lazy(() => import("./Blog/Index"));
export const BlogDetal = React.lazy(() => import("./BlogDetal/Index"));
export const Kategorie = React.lazy(() => import("./Kategorie/Index"));
export const KategorieDetal = React.lazy(() =>
  import("./KategoriaDetal/Index")
);
export const KategorieDodaj = React.lazy(() =>
  import("./KategoriaDodaj/Index")
);
export const Kliniki = React.lazy(() => import("./Kliniki/Index"));
export const Klinika = React.lazy(() => import("./Klinika/Index"));
export const Pacjenci = React.lazy(() => import("./Pacjenci/Index"));
export const Pacjent = React.lazy(() => import("./Pacjent/Index"));
export const Pytania = React.lazy(() => import("./Pytania/Index"));
export const Odpowiedzi = React.lazy(() => import("./Odpowiedzi/Index"));
export const Odpowiedz = React.lazy(() => import("./Odpowiedz/Index"));
export const Specjalisci = React.lazy(() => import("./Specjalisci/Index"));
export const SpecjalistaDetal = React.lazy(() =>
  import("./SpecjalistaDetal/Index")
);
export const Specjalizacje = React.lazy(() => import("./Specjalizacje/Index"));
export const Specjalizacja = React.lazy(() => import("./Specjalizacja/Index"));
export const SpecjalizacjeDodaj = React.lazy(() =>
  import("./SpecjalizacjeDodaj/Index")
);
export const Uzytkownicy = React.lazy(() => import("./Uzytkownicy/Index"));
export const UzytkownikDetal = React.lazy(() =>
  import("./UzytkownikDetal/Index")
);
export const Wizyty = React.lazy(() => import("./Wizyty/Index"));
export const WizytaDetal = React.lazy(() => import("./WizytaDetal/Index"));
export const Home = React.lazy(() => import("./Home/Index"));
