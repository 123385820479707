import React, { useContext, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import ContextMenu, { Position } from "devextreme-react/context-menu";
import List from "devextreme-react/list";
import { UserContext } from "../../contexts/auth";
import "./UserPanel.scss";

export default function UserPanel({ menuMode }) {
  const { user, logout } = useContext(UserContext);
  const navigate = useNavigate();

  function navigateToProfile() {
    navigate("/");
  }

  function navigateToTerapist() {
    navigate("/terapeuta");
  }

  function handleLogout() {
    navigate("/zaloguj-sie");
    logout();
  }

  function navigateToClinic() {
    navigate("/klinika");
  }

  let items = [
    {
      text: "Strona główna",
      icon: "globe",
      onClick: navigateToProfile,
    },
    {
      text: "Panel Terapeuty",
      icon: "user",
      onClick: navigateToTerapist,
    },
    {
      text: "Wyloguj się",
      icon: "runner",
      onClick: handleLogout,
    },
  ];

  if (user.is_clinic) {
    let new_items = [
      ...items.slice(0, 2),
      {
        text: "Panel Kliniki",
        icon: "user",
        onClick: navigateToClinic,
      },
      ...items.slice(2, 3),
    ];
    items = new_items;
  }
  const menuItems = useMemo(() => items, [logout]);

  return (
    <div className={"user-panel"}>
      <div className={"user-info"}>
        <div className={"image-container"}>
          <img
            src={
              user.profile_picture ? user.profile_picture : "/defaultUser.jpeg"
            }
            className={"user-image"}
          />
        </div>
        <div className={"user-name"}>{user.email}</div>
      </div>

      {menuMode === "context" && (
        <ContextMenu
          items={menuItems}
          target={".user-button"}
          showEvent={"dxclick"}
          width={210}
          cssClass={"user-menu"}
        >
          <Position
            my={{ x: "center", y: "top" }}
            at={{ x: "center", y: "bottom" }}
          />
        </ContextMenu>
      )}
      {menuMode === "list" && (
        <List className={"dx-toolbar-menu-action"} items={menuItems} />
      )}
    </div>
  );
}
